import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { setAccessToken } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addCaptchaScript, hideCaptchaBadge } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
declare var grecaptcha: any;

// type StateResponseProps = {
//   state: string;
// };

// const hardcodedStates = [
//   { state: "West Bengal" },
//   { state: "Uttarakhand" },
//   { state: "Uttar Pradesh" },
//   { state: "Tripura" },
//   { state: "Telangana" },
//   { state: "Tamil Nadu" },
//   { state: "Sikkim" },
//   { state: "Rajasthan" },
//   { state: "Punjab" },
//   { state: "Puducherry" },
//   { state: "Odisha" },
//   { state: "Nagaland" },
//   { state: "Mizoram" },
//   { state: "Meghalaya" },
//   { state: "Manipur" },
//   { state: "Maharashtra" },
//   { state: "Madhya Pradesh" },
//   { state: "Lakshadweep" },
//   { state: "Ladakh" },
//   { state: "Kerala" },
//   { state: "Karnataka" },
//   { state: "Jharkhand" },
//   { state: "Jammu and Kashmir" },
//   { state: "Himachal Pradesh" },
//   { state: "Haryana" },
//   { state: "Gujarat" },
//   { state: "Goa" },
//   { state: "Delhi" },
//   { state: "Dadra and Nagar Haveli and Daman and Diu" },
//   { state: "Chhattisgarh" },
//   { state: "Chandigarh" },
//   { state: "Bihar" },
//   { state: "Assam" },
//   { state: "Arunachal Pradesh" },
//   { state: "Andhra Pradesh" },
//   { state: "Andaman and Nicobar Islands" },
//   // Add more states as needed
// ];

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);

  // const [showStateDropdown, setShowStateDropdown] = useState(false);
  // const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
  //   []
  // );
  // const [filteredNewStates, setFilteredNewStates] = useState<
  //   StateResponseProps[]
  // >([]);
  // const [citiesFound, setCitiesFound] = useState(true);
  // const [states, setStates] = useState("");

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      navigate(ROUTES.PAYOUT_FORM);
    }
  }, [isLoggedIn]);
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     const handleLocationChange = () => {
  //       // Perform your back navigation logic here
  //       navigate(ROUTES.PAYOUT_FORM); // Change this to the appropriate route
  //     };

  //     window.addEventListener("popstate", handleLocationChange);

  //     return () => {
  //       window.removeEventListener("popstate", handleLocationChange);
  //     };
  //   }
  // }, [navigate, isLoggedIn]);
  // useEffect(() => {
  //   setFilteredNewStates(filteredCities);
  // }, [filteredCities]);

  // useEffect(() => {
  //   console.log(filteredCities);
  //   console.log(filteredNewStates);
  //   {
  //     setFilteredCities(hardcodedStates);
  //     // setFilteredNewStates(hardcodedStates);
  //     /*API.stateList()
  //     .then((states) => {
  //       setFilteredCities(states.stateList);
  //       console.log(states.stateList);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });*/
  //   }
  // }, []);
  // //State Dropdown:
  // const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = event.target.value.toLowerCase();

  //   const filtered = filteredCities.filter((state) =>
  //     state.state.toLowerCase().includes(inputValue)
  //   );

  //   setCitiesFound(filtered.length > 0);

  //   setFilteredNewStates(filtered);
  //   setShowStateDropdown(true);
  // };

  // const handleStateSelect = (selectedState: string) => {
  //   console.log("modal clicke", selectedState);

  //   setStates(selectedState);

  //   console.log("states", states);
  //   setShowStateDropdown(false);
  // };

  const RegisterValidation = Yup.object().shape({
    name: Yup.string()
      .required(t("registerPage.errors.invalidName") || "Enter valid name")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        t("registerPage.errors.invalidName") || "Enter valid name"
      ),
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      ),
    piCode: Yup.string().required(
      t("registerPage.errors.invalidCode") ||
        "*That’s not a valid code. Please check and enter the correct code"
    ),
    // state: Yup.string().required(
    //   t("Please enter a valid State") || "*Please enter a valid State"
    // ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP()
        .then(() => {
          // console.log("OTP Resent Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          name: "",
          mobile: "",
          piCode: "",
          email: "",
          // state: states,
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          if (isAgreed) {
            API.register(values).then(() => {
              gtagTrackEvent(GA_EVENTS.Registered);
              setShowOtpForm(true);
              startTimer();
            });
          } else {
            setCheckboxError("Please accept the terms and conditions");
            setShowError(true); // Optionally, show an error message
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          // setFieldValue,
        }) => {
          return (
            <Form
            // onSubmit={handleSubmit}
            // onClick={() => setShowStateDropdown(false)}
            >
              <div className="register-form">
                <p className="form-title ">{t("registerPage.title")}</p>

                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.piCode}
                    name="piCode"
                    maxLength={15}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.piCode")}
                  />
                </div>
                {errors.piCode && touched.piCode && (
                  <p className="error">{errors.piCode}</p>
                )}
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.name")}
                  />
                </div>
                {errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.mobile")}
                  />
                </div>
                {errors.mobile && touched.mobile && (
                  <p className="error">{errors.mobile}</p>
                )}
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.email")}
                  />
                </div>
                {!values.email && touched.email && (
                  <p className="error">
                    {t("registerPage.errors.invalidEmail")}
                  </p>
                )}
                {/*           <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    value={values.state}
                    name="state"
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.state")}
                    onChange={(e) => {
                      handleStateChange(e);

                      setFieldValue("state", e.target.value);
                    }}
                  />
                </div>
                {errors.state && touched.state && (
                  <p className="error">{errors.state}</p>
                )}
                {showStateDropdown && (
                  <div className="city dropdown">
                    <ul>
                      {filteredNewStates.map((state) => (
                        <li
                          key={state.state}
                          onClick={() => {
                            handleStateSelect(state.state);

                            setFieldValue("state", state.state);
                          }}
                        >
                          {state.state}
                        </li>
                      ))}
                      {!filteredNewStates.length ? (
                        <li className="nf">Not Found</li>
                      ) : null}
                    </ul>
                  </div>
                )}
                      */}
                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      {t("registerPage.placeholder.acceptText")}{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u>
                          {t("registerPage.placeholder.termsAndConditions")}
                        </u>
                      </span>
                    </span>
                    <input
                      autoComplete="off"
                      type="checkbox"
                      id="getStart_check"
                      name="getStart_check"
                      checked={isAgreed}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {checkboxError && showError && (
                  <p className="error">{checkboxError}</p>
                )}
              </div>
              <button className="btn btn-primary" type="submit">
                {t("registerPage.submit")}
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          grecaptcha
            .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
              action: "verify_otp",
            })
            .then((token: string) => {
              // console.log("Token:", token);
              API.verifyOTP(values.otp, token)
                .then((response) => {
                  hideCaptchaBadge();
                  store.dispatch(setAccessToken(response.accessToken));
                  setShowPayoutForm(true);
                  navigate(ROUTES.PAYOUT_FORM);
                  gtagTrackEvent(GA_EVENTS.Verify_OTP);
                })
                .catch((err) => {
                  const { messageId } = err;
                  switch (messageId) {
                    case ERROR_IDS.INVALID_OTP:
                      errors.setErrors({
                        otp:
                          t("registerPage.errors.invalidOtp") ||
                          "Please enter a valid OTP",
                      });
                      break;
                    default:
                      errors.setErrors({
                        otp: err.message,
                      });
                      break;
                  }
                });
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <p className="form-title">{t("registerPage.verifyOtp")}</p>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                </div>
                <span className="link" onClick={resendOtp}>
                  {t("registerPage.resend")}
                </span>
              </div>
              <button className="btn btn-primary" type="submit">
                {t("registerPage.verify")}
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }
  function renderPayoutForm() {
    return (
      <CashbackPayout
        onSubmit={(values) => {
          // Handle the selected payout method and values here
          // console.log("Selected payout method and values:", values);
          // For example, you can make an API call to save the payout details
          // and show the congratulations popup if the submission is successful
          setShowPayoutForm(false); // Hide the payout form after submission
        }}
      />
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
