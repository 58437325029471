import CloseIcon from "../assets/images/close.webp";
import { PropsWithChildren } from "react";
import { useGlobalModalContext } from "./GlobalModal";

export interface PopupProps extends PropsWithChildren {
  title?: string;
  modalBgClass?: string;
  className?: string;
  onClose?: () => void;
}

export default function Popup(props: PopupProps) {
  const { hideModal } = useGlobalModalContext();

  return (
    <div className={"popup " + props.className}>
      <div className="popup-bg"></div>
      <div className={"popup-modal " + props.modalBgClass}>
        <div className="header">
          {props.title && <p className="title">{props.title}</p>}
          <img
            src={CloseIcon}
            alt="Popup close"
            className="closeBtn"
            onClick={() => {
              hideModal();
              props.onClose && props.onClose();
            }}
          />
        </div>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
}
