import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Terms & Conditions">
      <br />
      <p dir="ltr">Terms and Conditions – Cashback</p>
      <p dir="ltr">
        1. This offer entitles the end user cashback on their Online Wallet or
        Bank Account Or UPI. The customer will be responsible for their choice
        of the cashback transfer method. The denomination of the cashback will
        be mentioned on the voucher/scratch card.
      </p>
      <p dir="ltr">
        2. Any consumer purchasing promotional product shall be eligible to
        receive a Voucher inside the pack. The customer shall receive a cashback
        of an amount equivalent to the value as mentioned in the Voucher
        (“Cashback/Voucher Amount”).
      </p>
      <p dir="ltr">
        3. By redeeming the Offer provided on the same, a participant agrees to
        be bound by the terms and conditions below (“Conditions”).
      </p>
      <p dir="ltr">
        4. These Conditions apply to every person ("Participant”) redeeming the
        offer given by Premier Sales Promotions Pvt Ltd, No claim shall lie for
        any redemption requests made after the expiry of the Offer Period.
      </p>
      <p dir="ltr">
        5. If you are not a wallet partner registered customer, you will be
        required to register on the wallet partner website or Mobile App to
        receive your wallet cashback.
      </p>
      <p dir="ltr">
        6. The customer will be responsible for entering details such as UPI ID,
        Account No, Online Wallet Mob Nos, etc. Premier Sales Promotions Pvt
        Ltd. will not be responsible for any incorrect entries or details on the
        promotion website. No change in details will be entertained once you
        have submitted the booking.
      </p>
      <p dir="ltr">
        7. All Participants agree that wallet partner offering discount vouchers
        may at any time stipulate additional terms and conditions for redemption
        of the discount vouchers and such terms and conditions shall be binding
        on the Participants and shall apply to every redemption of
        recharge/discount voucher by the Participants.
      </p>
      <p dir="ltr">
        8. The Wallet Partner / Payment processing partner holds the right to
        refuse transactions deemed fraudulent as per their policies.
      </p>
      <p dir="ltr">
        In the event of any fraud detection, the wallet partner / Payment
        processing partner reserves the right to modify/change, discontinue,
        terminate, withdraw the Offer at any time during the Offer Period.
      </p>
      <p dir="ltr">
        9. The Participant acknowledges that relevant state and central taxes
        may be deducted by the merchant while redeeming the discount offer and
        therefore, the amount reflected in the voucher may not be the same as
        the final redemption value received by the Participant.
      </p>
      <p dir="ltr">
        10. In no event shall Premiere Sales Promotions Pvt Ltd or its partner
        agency be held liable for any issues relating to the redemption of the
        voucher including the redemption request not being honored by the wallet
        partner / Payment processing partner.
      </p>
      <p dir="ltr">
        11. In no event shall the maximum liability exceed the value of the
        voucher i.e. Rs. 10
      </p>
      <p dir="ltr">
        12. Any disputes or differences in connection with the offer shall be
        governed by the laws of India and shall be subject to the exclusive
        jurisdiction of the Courts at Bengaluru.
      </p>
      <p dir="ltr">
        13. The wallet partner / Payment processing partner reserves the right
        to restrict cashback for any account with suspicious behavior or invalid
        details/credentials. The wallet partner has the right to ask for
        documents to prove the customer/user identity.
      </p>
      <p dir="ltr">
        14. This offer is subject to promotional availability and government
        regulations.
      </p>
      <p dir="ltr">Terms &amp; Conditions for Merchandise</p>
      <p dir="ltr">
        1. The images shown are indicative of the product being offered, the
        actual product, its colour and design may vary from the displayed image.
      </p>
      <p dir="ltr">
        2. Gifts/Products will be accompanied by warranty information wherever
        applicable from the manufacturer and claims must be directed to the
        manufacturers directly.
      </p>
      <p dir="ltr">
        3. Merchandise once ordered cannot be returned/ exchanged unless the
        merchandise is delivered defective. In case of the product delivered to
        you is defective, please contact our helpdesk within 24 hours of
        delivery of the merchandise, in case of failure to do so, the reward
        shall be considered as accepted by customer.
      </p>
      <p dir="ltr">
        4. Defective goods will be replaced, or alternate product will be
        delivered if the defect is reported within 24 hours of receiving the
        defective product. Call helpdesk to report a product as defective.
      </p>
      <p dir="ltr">
        5. Premier Sales Promotions Pvt Ltd. will do an address specific
        dispatch of redeemed items.
      </p>
      <p dir="ltr">
        The customer shall be solely responsible for ensuring that correct
        address is updated and concerned people at his home/office (as the case
        may be) are kept informed. Premier Sales Promotions Pvt Ltd. will not be
        responsible for non-receipt of redeemed items on account of deliveries
        done to any other person at the registered address other than the
        customer.
      </p>
      <p dir="ltr">
        6. No dispatch shall be made to P.O. Box addresses or to addresses
        outside India.
      </p>
      <p dir="ltr">
        7. To the extent permitted by law, Premier Sales Promotions Pvt Ltd.
        makes no representations or warranties as to the quality, suitability,
        or merchantability of any gift/ reward. Warranty claims must be directed
        to the manufacturer.
      </p>
      <p dir="ltr">8. The product cannot be exchanged for cash any point.</p>
      <p dir="ltr">
        9. No. change in color and brand will be entertained once the order is
        placed.
      </p>
      <p dir="ltr">
        10. In case the product is not available, then an alternate product (as
        per availability) will be offered to you.
      </p>
      <p dir="ltr">General Terms &amp; Conditions</p>
      <p dir="ltr">
        1. This offer is not available to employees of Parle Biscuits Pvt. Ltd.
        and PREMIER SALES PROMOTIONS PVT LTD, its clients or agencies or trade
        partners, dealers, distributors, and their immediate families.
      </p>
      <p dir="ltr">
        2. This offer is subject to promotional availability. This offer is not
        exchangeable for “cash”.
      </p>
      <p dir="ltr">3. This offer is not for sale.</p>
      <p dir="ltr">
        4. Photocopied, torn or mutilated Vouchers will not be accepted.
      </p>
      <p dir="ltr">
        5. This voucher has no monetary value, is non-transferable and cannot be
        used in conjunction with any other promotional offers.
      </p>
      <p dir="ltr">
        6. The usage of this voucher is incumbent upon the terms &amp; and
        conditions to be followed by the voucher holder/purchaser. Such
        conditions need to be followed by the voucher holder/purchaser.
      </p>
      <p dir="ltr">
        7. A maximum of one voucher may be used per person, per mobile number,
        per email id per account during the promotion period.
      </p>
      <p dir="ltr">
        8. Only one voucher per booking will be entertained. Multiple Vouchers
        cannot be booked together.
      </p>
      <p dir="ltr">
        9. If you are registered with Do Not Disturb (DND) service, you will not
        receive an SMS confirmation from us. Please mail us at
        feedback@bigcity.in for any queries. If Premier Sales Promotions Pvt.
        Ltd has already emailed or sent by SMS, Premier Sales Promotions Pvt.
        Ltd will not re-send the voucher code to any new or corrected email
        address or phone number nominated by the entrant.
      </p>
      <p dir="ltr">
        10. The “How to Redeem” instructions form a part of these Terms &amp;
        Conditions. For detailed T&amp;C’s please visit
        <strong> www.hideandseekoffer.com. </strong> Any costs associated with
        entering the promotion, including accessing the promotional website, are
        the responsibility of each entrant. Also, regular call and message rates
        will apply as per the consumer telecom operator in their respective
        circles.
      </p>
      <p dir="ltr">
        11. Parle Biscuits Pvt. Ltd. or Premier Sales Promotions Pvt. Ltd. is
        not responsible in case the Voucher codes have expired for any reason
        whatsoever.
      </p>
      <p dir="ltr">
        12. Parle Biscuits Pvt. Ltd. or Premier Sales Promotions Pvt. Ltd and
        the Stores are not responsible for any loss or theft of the Vouchers.
      </p>
      <p dir="ltr">
        13. No duplicate Voucher shall be issued to the customers.
      </p>
      <p dir="ltr">
        14. Parle Biscuits Pvt. Ltd. Or Premier Sales Promotions Pvt. Ltd. is
        not responsible for any problems or technical malfunction of any
        telephone or network or lines, servers or providers, computer equipment,
        software, technical problems or traffic congestion on a mobile network,
        or any combination thereof, or any other technical failures including
        any damage to entrant's or any other person's mobile handset or computer
        related to, or resulting from, participation in this promotion or the
        downloading of any materials related to this promotion.
      </p>
      <p dir="ltr">
        15. If this promotion is interfered with in any way or is not capable of
        being conducted as reasonably anticipated due to any reason beyond the
        reasonable control of the Promoter, including but not limited to
        technical difficulties, unauthorized intervention or fraud, the Promoter
        reserves the right, in its sole discretion, to the fullest extent
        permitted by law (a) to invalidate any customer; or (b) subject to any
        written directions from a regulatory authority, to modify, suspend,
        terminate or cancel the promotion, as appropriate.
      </p>
      <p dir="ltr">
        16. If for any reason this promotion is not capable of running as
        planned because of infection by computer virus, bugs, tampering,
        unauthorized intervention, technical failures or any other causes beyond
        the reasonable control of the Promoter which corrupt or affect the
        administration, security, fairness, integrity or proper conduct of this
        promotion, the Promoter reserves the right in its sole discretion to
        cancel, terminate, modify or suspend the promotion, subject to any
        written directions made under applicable State or Territory legislation.
      </p>
      <p dir="ltr">
        17. Caution: Any attempt to deliberately damage the Promoter's
        promotional website or the information on the website, to cause
        malicious damage or interference with the normal functioning of the
        website, or to otherwise undermine the legitimate operation of this
        promotion may be a violation of criminal and civil laws and should such
        an attempt be made, whether successful or not, the Promoter reserves the
        right to seek damages to the fullest extent permitted by law. If the
        Promoter suffers loss or incurs any costs in connection with any breach
        of these Terms or any other legal obligation by an entrant, the entrant
        agrees to indemnify the Promoter for those losses, damages, and costs.
      </p>
      <p dir="ltr">
        18. Parle Biscuits Pvt. Ltd. Or Premier Sales Promotions Pvt. Ltd.
        accepts no responsibility, financial or otherwise for the misuse of
        Premier Sales Promotions Pvt. Ltd. Vouchers and are not liable for any
        personal loss or injury and cannot be held responsible for any
        disagreement concerning the quality/ availability of featured offers.
        Premier Sales Promotions Pvt. Ltd., its clients, agents, and
        distributors cannot replace any lost, stolen or damaged Vouchers and
        reserve the right to withdraw or amend any details and/or prices without
        notice.
      </p>
      <p dir="ltr">
        19. Premier Sales Promotions Pvt. Ltd. is acting as an executing agency
        for this promotional offer and holds no legal and financial liability on
        unclaimed offers, incentives, Vouchers, etc beyond the applicable period
        of the promotional offer.
      </p>
      <p dir="ltr">
        20. Parle Biscuits Pvt. Ltd. does not endorse the quality of any of the
        products or services being offered as Vouchers under the Offer. Any
        complaints or queries pertaining to the same would have to be taken up
        directly with the Premier Sales Promotions Pvt Ltd. of such a product or
        service.
      </p>
      <p dir="ltr">
        21. The Terms and Conditions of the offer can be changed during the
        promotional period by PREMIER SALES PROMOTIONS PVT LTD or by Parle
        Biscuits Pvt. Ltd.
      </p>
      <p dir="ltr">
        22. These Terms &amp; Conditions are subject to Indian Law and the
        exclusive jurisdiction of the Courts in Bangalore.
      </p>
      <p dir="ltr">
        23. Cashback Offer valid from 01/01/2024 to 15/04/2024 (Offer Period).
      </p>
      <p dir="ltr">24. Promoter: Parle Biscuits Pvt. Ltd.</p>
      <p dir="ltr">
        25. All offers are supplied by Premier Sales Promotions Pvt. Ltd. 4th
        Floor, Mitra Towers, 10/4 Kasturba Road, Bangalore 560001, India.
        E-mail: feedback@bigcity.in. All queries should be sent to this address.
      </p>
      <br />
    </Popup>
  );
};

export default TermsPopup;
