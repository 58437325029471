import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import "./ThankYou.scss";
import { useTranslation } from "react-i18next";
import repeatUserGif from "../../assets/images/anim/RepeatUsersOfferAnimation.gif";
import congratulationImage from "../../assets/images/anim/congrats-img.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

const ThankYou = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // Track the Thank You page visit when the component mounts
    gtagTrackEvent(GA_EVENTS.Thank_You);
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <MainLayout className="upload-selfie-page">
      <div className="congratulation-container">
        <img
          src={congratulationImage}
          alt="Congratulation"
          className="congratulation-image"
        />

        {state?.newUser ? (
          <>
            <p className="text1">{t("tyPage.newUser.text1")}</p>
            <p className="text2">{t("tyPage.newUser.text2")}</p>
            <div className="note">
              <p className="text3">{t("tyPage.newUser.text3")}</p>
              <p className="text4">{t("tyPage.note")}</p>
            </div>
          </>
        ) : (
          <>
            <p className="congratulation-text">
              {t("tyPage.repeatUser.congratulationText")}
            </p>
            <p className="text1">{t("tyPage.repeatUser.text1")}</p>
            {/* Uncomment the following section if you want to display the repeatUserGif */}
            {/*<div className="note">
              <p className="text3">{t("tyPage.repeatUser.text3")}</p>
              <p className="text4">{t("tyPage.note")}</p>
              <img className="repeatUser_gif" src={repeatUserGif} alt="repeatUserGif" />
            </div>*/}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default ThankYou;
