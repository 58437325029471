import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Contact-us" className="contact-us-popup">
      {/*<p className="text1">FOR ANY QUERIES</p>*/}
      <p className="text2">EMAIL US:</p>
      <p className="text3">
        <a href={"mailto:feedback@bigcity.in"}>feedback@bigcity.in</a>
      </p>
      <p className="text4">OR</p>
      <p className="text5">CALL US AT:</p>
      <p className="text6"> 08040554859</p>
      <p className="text7">10:30am - 5:30pm(Mon-Fri)</p>
      <p className="text8">(Lines closed on public & bank holidays)</p>
      <p className="text3">
        Premier Sales Promotions Pvt. Ltd, Mitra Towers, Bangalore - 560001
        {/*<a href={"Email: feedback@bigcity.in"}>feedback@bigcity.in</a>*/}
      </p>
    </Popup>
  );
};

export default ContactUsPopup;
