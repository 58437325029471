import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import Illustration from "../../assets/images/home/home-page-illustration.png";
import centerimage from "../../assets/images/home/centerImage.png";
import buttonimg from "../../assets/images/home/Button-img.png";
import "./Home.scss";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import { Trans, useTranslation } from "react-i18next";
import { useGlobalModalContext } from "../../helpers/GlobalModal";
import { useEffect, useRef, useState } from "react";
import { LANGUAGE, LANGUAGES } from "../../lib/consts";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  addCaptchaScript,
  gtagTrackEvent,
  GA_EVENTS,
  hideCaptchaBadge,
  showCaptchaBadge,
} from "../../lib/utils";

declare var grecaptcha: any;

const Home = () => {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      navigate(ROUTES.PAYOUT_FORM);
    } else {
      addCaptchaScript(() => {
        showCaptchaBadge();
      });
    }
  }, [isLoggedIn]);
  useEffect(
    () => () => {
      hideCaptchaBadge();
    },
    []
  );

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     const handleLocationChange = () => {
  //       // Perform your back navigation logic here
  //       navigate(ROUTES.PAYOUT_FORM); // Change this to the appropriate route
  //     };

  //     window.addEventListener("popstate", handleLocationChange);

  //     return () => {
  //       window.removeEventListener("popstate", handleLocationChange);
  //     };
  //   }
  // }, [navigate, location.pathname, isLoggedIn]);

  const getStarted = () => {
    API.getStarted("get_started")
      .then((res) => {
        navigate(ROUTES.REGISTER);
        gtagTrackEvent(GA_EVENTS.Get_Started);
      })
      .catch((error) => {
        setShowError(true); // Set showError to true for UI feedback
        console.error("Error in API.getStarted:", error);

        if (error.code === 401) {
          // Session not found, handle as needed
          console.error("User not authenticated:", error.message);
        }
      });
  };

  return (
    <div className="home-page">
      <div className="content">
        <img src={Logo} alt="logo" className="logo" />
        <p className="closed">
          Thank you for your interest. <br />
          This campaign is now closed.
        </p>
        {/*
    <img src={Illustration} alt="illustration" className="illustration" />
    <div className="text-wrapper">
    <img src={centerimage} alt="centerimg" className="centerimage" />
        </div>
        <div className="content-wrapper">
          <select
            className="language-selection header-lang"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
          >
            <option key={""} value={""}>
              CHOOSE LANGUAGE
            </option>
            {Object.keys(LANGUAGES).map((key) => {
              if (i18n.language === key) {
                return (
                  <option key={key} value={key} selected>
                    {LANGUAGES[key as LANGUAGE]}
                  </option>
                );
              }

              return (
                <option key={key} value={key}>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            })}
          </select>

          <div className="get-started-container" onClick={getStarted}>
            <img
              src={buttonimg}
              alt="get-started"
              className="get-started-image"
            />
            <span className="get-started-text">{t("homePage.getStarted")}</span>
          </div>
        </div>
      */}
      </div>
    </div>
  );
};

export default Home;
