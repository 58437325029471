// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CameraIcon from "../../assets/images/camera-icon.png";
import MainLayout from "../../layouts/MainLayout";
import "./CashbackPayout.scss";
import { toBase64 } from "../../lib/utils";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import { Trans, useTranslation } from "react-i18next";
import { error } from "console";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

// Import statements...

interface CashbackPayoutProps {
  onSubmit: (values: any) => void;
}

const CashbackPayout: React.FC<CashbackPayoutProps> = ({ onSubmit }) => {
  const { t }: { t: Function } = useTranslation();
  const PayoutValidation = Yup.object().shape({
    payoutMethod: Yup.string().required(
      t("cashbackPayout.errors.chooseMethod")
    ),
    upiNumber: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "upi"
        ? schema
            .required(t("cashbackPayout.errors.upiNumber") || "Enter valid UPI")
            .matches(
              /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
              t("cashbackPayout.errors.upiNumber") || "Enter valid UPI"
            )
        : schema
    ),
    name: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "neft"
        ? schema
            .required(
              t("cashbackPayout.errors.invalidName") || "Enter valid name"
            )
            .matches(
              /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
              t("cashbackPayout.errors.invalidName") || "Enter valid name"
            )
        : schema
    ),

    accountNumber: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "neft"
        ? schema
            .required(
              t("cashbackPayout.errors.invalidAccount") ||
                "Enter valid account number"
            )
            .matches(
              /^\d{7,20}$/,
              t("cashbackPayout.errors.invalidAccount") ||
                "Enter valid* account number"
            )
        : schema
    ),
    ifscCode: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "neft"
        ? schema
            .required(
              t("cashbackPayout.errors.invalidIFSC") || "Enter valid IFSC code"
            )
            .matches(
              /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
              t("cashbackPayout.errors.invalidIFSC") || "Enter valid IFSC code"
            )
        : schema
    ),
    mobileNumber: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "e-wallet"
        ? schema
            .required(
              t("cashbackPayout.errors.invalidMobile") ||
                "*Please enter a valid 10-digit number"
            )
            .matches(
              /^[56789][0-9]{9}$/,
              t("cashbackPayout.errors.invalidMobile") ||
                "*Please enter a valid 10-digit number"
            )
        : schema
    ),
    // Add validation schema for specific payout methods if needed
  });

  const navigate = useNavigate();

  return (
    <MainLayout className="register-page">
      <Formik
        initialValues={{
          payoutMethod: "upi",
          // Add more fields based on the selected payout method
          upiNumber: "",
          name: "", // Add Name for NEFT
          accountNumber: "", // Add Account Number for NEFT
          ifscCode: "", // Add IFSC Code for NEFT
          mobileNumber: "", // Add Mobile Number for E-Wallet
          // Add other fields for NEFT, E-Wallet, etc.
        }}
        validationSchema={PayoutValidation}
        onSubmit={(values, { setSubmitting }) => {
          // Add logic for handling the selected payout method
          // console.log(values);

          switch (values.payoutMethod) {
            case "upi":
              API.saveUPIDetails(values.upiNumber);
              gtagTrackEvent(GA_EVENTS.UPI_click);
              break;
            case "neft":
              API.saveNEFTDetails(
                values.name,
                values.accountNumber,
                values.ifscCode
              );
              gtagTrackEvent(GA_EVENTS.NEFT_click);
              break;
            case "e-wallet":
              API.saveEWalletDetails(values.mobileNumber);
              gtagTrackEvent(GA_EVENTS.Amazon_Pay);
              break;
            default:
              break;
          }
          onSubmit(values);
          setSubmitting(false);
          //   console.log(errors);
          // Navigate to the Thank You page
          navigate(ROUTES.THANK_YOU);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
        }) => (
          <Form className="cashback-payout-form" onSubmit={handleSubmit}>
            {/* Add your payout method selection UI here */}
            <div className="cashback-payout-title">
              {t("cashbackPayout.choosePayout")}
            </div>

            <div className="payout-option-container">
              <div
                className={`payout-option ${
                  values.payoutMethod === "upi" ? "selected" : ""
                }`}
                onClick={(e) => {
                  // e.target.classList.add("selected");
                  setFieldValue("payoutMethod", "upi");
                  gtagTrackEvent(GA_EVENTS.UPI_click);
                }}
              >
                {t("cashbackPayout.upi")}
              </div>
              <div
                className={`payout-option ${
                  values.payoutMethod === "neft" ? "selected" : ""
                }`}
                onClick={(e) => {
                  // e.target.classList.add("selected");
                  setFieldValue("payoutMethod", "neft");
                }}
              >
                {t("cashbackPayout.neft")}
              </div>
              <div
                className={`payout-option ${
                  values.payoutMethod === "e-wallet" ? "selected" : ""
                }`}
                onClick={(e) => {
                  // e.target.classList.add("selected");
                  setFieldValue("payoutMethod", "e-wallet");
                }}
              >
                {t("cashbackPayout.eWallet")}
              </div>
            </div>

            {/* Add input fields for selected payout method */}
            {/* Example: UPI input fields */}
            <div className="payout-input-container">
              {values.payoutMethod === "upi" && (
                <div className="input-group">
                  <label htmlFor="upiNumber"></label>
                  <Field
                    type="text"
                    id="upiNumber"
                    name="upiNumber"
                    placeholder={t("cashbackPayout.enterUpi")}
                  />
                  <ErrorMessage
                    name="upiNumber"
                    component="p"
                    className="error"
                  />
                </div>
              )}

              {/* Example: NEFT input fields */}
              {values.payoutMethod === "neft" && (
                <div>
                  <div className="input-group">
                    <label htmlFor="name"></label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      placeholder={t("cashbackPayout.enterName")}
                      maxLength={50}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="accountNumber"></label>
                    <Field
                      type="number"
                      id="accountNumber"
                      name="accountNumber"
                      maxLength={20}
                      placeholder={t("cashbackPayout.enterAccountNumber")}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="ifscCode"></label>
                    <Field
                      type="text"
                      id="ifscCode"
                      name="ifscCode"
                      placeholder={t("cashbackPayout.enterIfscCode")}
                      maxLength={11}
                    />
                  </div>
                  <ErrorMessage name="name" component="p" className="error" />
                  <ErrorMessage
                    name="accountNumber"
                    component="p"
                    className="error"
                  />
                  <ErrorMessage
                    name="ifscCode"
                    component="p"
                    className="error"
                  />
                </div>
              )}

              {/* Example: E-Wallet input fields */}
              {values.payoutMethod === "e-wallet" && (
                <div className="input-group">
                  <label htmlFor="mobileNumber"></label>
                  <Field
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    maxLength={10}
                    placeholder={t("cashbackPayout.enterMobileNumber")}
                  />
                  <ErrorMessage
                    name="mobileNumber"
                    component="p"
                    className="error"
                  />
                </div>
              )}
            </div>

            <button className="btn btn-primary" type="submit">
              {t("cashbackPayout.submit")}
            </button>
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default CashbackPayout;
