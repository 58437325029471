import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup" title={"Redeem now"}>
      <p className="text1">coming soon</p>
    </Popup>
  );
};

export default HowTORedeemPopup;
